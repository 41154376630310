<template>
    <div class="informationDetail">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="" style="width:1200px;margin: 0 auto;height:60px;display:flex;align-items:center;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/information' }">资讯</el-breadcrumb-item>
                <el-breadcrumb-item>资讯详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="informationDetail_main">
            
            <div class="informationDetail_main_left">
                <div class="c_title">{{informadtionDetail.informationTitle}}</div>
                <div style="text-align:left;margin-left:20px;margin-right:20px;width:100%;">
                    <span class="c_time" >{{informadtionDetail.intime}}</span>
                    <span class="c_laiyuan">来源:{{informadtionDetail.informationSource}}</span>
                    <!-- <span style="float:right;margin-right:40px;"><img style="width:24px;height:14px;" src="@/assets/login/block.png">111</span> -->
                </div>
                <div class="c_content" v-html="informadtionDetail.informationContent"></div>
            </div>
            <div class="informationDetail_main_right">
                <div class="informationDetail_main_right_freeLive" v-if="freeLiveList.length>0">
                    <div class="informationDetail_main_right_freeLive_header">
                        <span>免费直播</span>
                        <!-- <span @click="goMore()">更多 ></span> -->
                    </div>
                    <div class="informationDetail_main_right_freeLive_list">
                        <div class="informationDetail_main_right_freeLive_list_item"  v-for="(item,index) in freeLiveList" :key="index" >
                            <div class="informationDetail_main_right_freeLive_list_item_left">
                                <img :src="item.teacherHeaderPath" alt="">
                                <span>{{item.teacherName}}</span>
                            </div>
                            <div class="informationDetail_main_right_freeLive_list_item_right">
                                <div class="informationDetail_main_right_freeLive_list_item_right_top">
                                    <span v-if="item.type==0">直播中</span>
                                    <span v-else style="width:1px"></span>
                                    <span>{{item.liveTIme}}</span>
                                </div>
                                <div class="informationDetail_main_right_freeLive_list_item_right_bottom">
                                    <p style="width:80%;">
                                        <span>{{item.liveName}}</span>
                                    </p>
                                    <button v-if="item.type==0" @click="toLiveDetail(item)" style="cursor:pointer;">进入直播</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="informationDetail_main_right_bottom">
                    <div class="informationDetail_main_right_bottom_icon">
                        <p>
                            <img src="@/assets/ask/information_icon.png" alt="">
                            <span>备考干货</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/consult.png" alt="">
                            <span>财务资讯</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/alarm.png" alt="">
                            <span>实时互动</span>
                        </p>
                    </div>
                    <div class="informationDetail_main_right_bottom_QRcode">
                        <img :src="website.qrCodeRight" alt />
                    </div>
                    <div class="informationDetail_main_right_bottom_text">下载APP，实时查看老师答疑</div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { freelive_list } from "@/api/home";
import { information_firstType,information_detail } from "@/api/other";
import { wangzhanpeizhi } from "@/api/login";
export default {
    name:'informationDetail',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            informationId:'',
            freeLiveList:[],
            informadtionDetail:{},
            isUserInfo:false,
            website:{},
        }
    },
    mounted(){
        this.informationId = this.$route.query.informationId;
        this.freelive_list()
        this.information_detail()
        this.wangzhanpeizhi();
    },
    methods:{
        wangzhanpeizhi(){
            wangzhanpeizhi().then(res=>{
                console.log("网站配置");
                if(res.data.code == 0){
                    this.website = res.data.data;
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
        goMore(){
            let routeUrl =this.$router.resolve({
                path: '/liveList',
                query: {
                    
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        checkLogin(){
            const userInfo = localStorage.getItem("setuserInfo");
            if(userInfo==null || userInfo=='null'){
                this.isUserInfo=true;
                setTimeout(() => {
                    this.isUserInfo=false;
                }, 0);
                return false
            }else{
                return userInfo
            }
        },
        // 去直播间
        toLiveDetail(item){
            // console.log(item)
            // var url='https://live.polyv.cn/watch/'+item.channelId
            // window.open(url, '_blank');
            const userInfo = this.checkLogin();
            if(!userInfo){
                return false
            }

            let routeUrl = this.$router.resolve({
                path: '/liveVideo',
                query: {
                    channelId:item.channelId
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        //详情
        information_detail(){
            var obj={
                informationId:this.informationId
            }
            information_detail(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.informadtionDetail=res.data.data
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 免费直播列表
        freelive_list(){
            freelive_list().then(res=>{
                console.log(res)
                if(res.data.code==0){
                    var arr = res.data.data.slice(0,4);
                    console.log(arr);
                    this.freeLiveList=arr;
                }else{
                    this.$message.error(res.data.msg);
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        
    }
}
</script>

<style>
    .c_title{
        margin-top: 20px;
        text-align: left;
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        color: #3A3A3A;
        line-height: 30px;
    }
    .c_time{
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
    }
    .c_laiyuan{
        margin-left:20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        font-size:14px;
    }
    .c_content{
        padding-left:20px;
        padding-right:20px;
        font-size: 16px;
        line-height: 27px;
        color: #50505a;
        word-wrap: break-word;
        margin-top:20px;
        margin-bottom:80px;
    }
    .c_content>p{
        text-align:left;
        padding-bottom:10px;
        color:#50505a;
        font-family: Helvetica Neue,Helvetica,Arial,Hiragino Sans GB,Microsoft Yahei,STXihei,SimHei,WenQuanYi Micro Hei,Droid Sans,sans-serif;
    }
    .c_content img{
        width: 100%;
        display: block;
        pointer-events: auto;
    }
</style>